import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from "../components/footer";
import Nav from "../components/nav";
import Hero from "../components/hero-standard";

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Bus Open Data package info requested" />
        <Nav activeTab="packages" />
        <div>
            <div>

              <Hero h1="Information requested" h2="We'll be in touch" />
              <div>

                <div className="article">
                  <div className='article-inner'>
                    <p style={{ textAlign: 'center', fontSize: '1.3em', color: '#3f6375', lineHeight: '150%' }}>
                      Thank you for your request for more information about our Bus Open Data package.
                    </p>
                    <p style={{ textAlign: 'center', fontSize: '1.3em', color: '#3f6375', lineHeight: '150%' }}>
                      A friendly member of our team will be in touch as soon as possible with more information.
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage
